export default {
    _state : {
        current_route : null,
        args : []
    },
    _bindUI : function(){
        let self = this;
        $(window).on('hashchange', function() {
            self._update();
        });

        self._update();
    },
    _update : function() {
        let self = this;

        if (self.current_route) {
            self.current_route.off && self.current_route.off.apply(self.current_route, []);
        }

        self._state.args.clear && self._state.args.clear();
        self._state.current_route = null;

        const found = self._state.args.routes.some(function(route) {
            const match = window.location.hash.match(route.path);

            if (!match) {
                return false;
            }

            self._state.current_route = route;
            self._state.current_route.on && self._state.current_route.on.apply(self._state.current_route, Array.prototype.slice.apply(match, [1]));
            return true;
        });

        if (!found) {
            self._state.args.default && self._state.args.default();
        }

        self._state.args.update && self._state.args.update();
    },
    init: function(args){
        let self = this;
        self._state.args = args;
        self._bindUI();

        return self;
    }
};