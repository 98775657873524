import Request from '../../components/request';

class Address {
    constructor(args) {
        let self = this;
        if (!args.root || !args.root.length) return;
        self._elems.$_ = args.root;

        self._init();
    };

    _elems = {
        $_     : $(),
        $input : $()
    };

    _update = (e) => {
        let self = this;

        self._elems.$input = $(e.target).closest('.input.is-address');
        if (self._elems.$input.length === 0)  return;

        let $input = self._elems.$input;

        if ($input.attr('data-last-value') === $(e.target).val()) return;

        if ($(e.target).val().length < 1) return;

        $input.attr('data-last-value', $(e.target).val());
        $input.find('input[type="hidden"]').val('');

        if ($input.attr('data-last-timeout-id')) {
            clearTimeout(parseInt($input.attr('data-last-timeout-id')));
        }

        let timeoutId = setTimeout(function() {
            $input.attr('data-last-timeout-id', '');
            $input.addClass('loading');

            Request.send({
                path: '/api/address_elements',

                data: {
                    address: 		(($input.attr('data-parent_address') !== undefined && $input.attr('data-parent_address') != '') ? $input.attr('data-parent_address') + ' ' : '') + $(e.target).val(),
                    only_regions: 	$input.hasClass('only_regions'),
                    to_locality: 	$input.hasClass('to-locality'),
                    streets_only: 	$input.hasClass('streets-only'),
                    parent_id: 		$input.attr('data-parentid') || 0,
                },

                done: function(response) {
                    $input.removeClass('loading');

                    if (response.address_elements.length == 0) {
                        $input.removeClass('showing-results');
                        return;
                    }

                    let $address_elements = $input.find('.input__address-elements');
                    $address_elements.html('');

                    response.address_elements.forEach(function(address_element) {
                        var address = address_element.address;

                        response.address_parts.forEach(function(address_part) {
                            address = address.replace(new RegExp('(' + address_part + ')', 'ig'), '<b>$1</b>');
                        });

                        let $a = $('<a />');
                        $a.addClass('input__address-element');
                        $a.attr('href', '#');
                        $a.html(address);
                        $a.attr('data-id', (address_element.id_orig) ? address_element.id_orig : address_element.id);
                        $a.attr('data-parentid', (address_element.parent1_id != '') ? address_element.parent1_id : address_element.parent0_id);
                        $a.appendTo($address_elements);
                    });

                    $input.hasClass('focused') && $input.addClass('showing-results');
                }
            });
        }, 500);

        $input.attr('data-last-timeout-id', timeoutId);
    };

    _bindUI = () => {
        let self = this;

        self._elems.$_.on('keyup', 'input[type="text"]', function(e) {
            self._update(e);
        });

        self._elems.$_.on('change', 'input[type="text"]', function(e){
            self._elems.$input = $(e.target).closest('.input.is-address');
            if (self._elems.$input.length === 0)  return;

            self._elems.$input.addClass('focused');
            if (self._elems.$input.find('.input__address-elements').children().length === 0) {
                self._update(e);
                return;
            }

            self._elems.$input.addClass('showing-results');
        });

        self._elems.$_.on('focus', 'input[type="text"]', function(e){
            self._elems.$input = $(e.target).closest('.input.is-address');
            if (self._elems.$input.length === 0)  return;

            self._elems.$input.addClass('focused');
            if (self._elems.$input.find('.input__address-elements').children().length === 0) {
                self._update(e);
                return;
            }

            self._elems.$input.addClass('showing-results');
        });

        self._elems.$_.on('click', function(e) {
            let $inputAddressElement = $(e.target).closest('.input__address-element');

            if (!$inputAddressElement.length) {
                if ($(e.target).closest('.input.is-address').length) return;

                let $input = $('.input.is-address');

                if (!$input.length) return;

                $input.removeClass('showing-results focused');
                return;
            }

            let $input = $inputAddressElement.closest('.input.is-address');

            if (!$input.length) return;

            e.preventDefault();
            $input.attr('data-newparentid', $inputAddressElement.attr('data-parentid'));
            $input.find('input[type="text"]').val($inputAddressElement.text());
            $input.find('input[type="hidden"]')
                .val($inputAddressElement.attr('data-id'))
                .trigger('change');

            $input.removeClass('showing-results').addClass('changed');
            $input.trigger('change');
            $input.parent().removeClass('error');
            $input.next('.form-field__message').text('').hide();

            let $location = self._elems.$_.find('.form-field[data-name="location"]');
            if (!$location.length) return;
            $location.removeClass('error');
        });
    };

    _init = () => {
        let self = this;

        self._bindUI();
    }
}

export default Address;