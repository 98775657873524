import Vars from './../../vars';

import Utils from './../../components/utils';

import _NAV from './../Const/_NAV';

class Banner {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _elems = {
        $content : $(),
        $cash     : $()
    };

    _const = {
        entities : 'banners'
    };

    _args = {
        page : ''
    };

    _state = {
        items : {}
    };

    _setArgs = (args) => {
        let self = this;

        self._args.page = args.page;
    };

    _bindElements = () => {
        let self = this;

        self._elems.$content = !self._elems.$content.length ? $('.content') : self._elems.$content;
        if(!self._elems.$content.length) return;

        self._elems.$cash = $('.cash');

        self._elems.$tabs = self._elems.$content.find('.content-tabs');

        self._bindUI();
    };

    _bindUI = () => {
        let self = this;

        self._bindTabs();
    };

    _bindTabs = () => {
        let self = this;
        if (!self._elems.$tabs.length) return;

        self._elems.$tabs.find('.content-tabs-heading a').click(function(e){
            e.preventDefault();
            self._elems.$tabs.find('.content-tabs-heading a').removeClass('--active');
            $(this).addClass('--active');

            self._elems.$tabs.find('.content-tabs-content [data-name]').removeClass('--active');
            self._elems.$tabs.find('.content-tabs-content [data-name="' + $(this).data('target') + '"]').addClass('--active');
        });

        self._elems.$content.find('.content-tabs-heading a:first').trigger('click');
    };

    _index = () => {
        let self = this;

        self._elems.$cash.trigger('update');

        let nav = Utils.renderTemplate('nav', {
            nav    : _NAV,
            active : 'banners'
        });
        let heading = Utils.renderTemplate('heading', {
            title : 'Баннеры'
        });
        let tabs = Utils.renderTemplate('tabs', {
            tabs : {
                f240_400   : {
                    title   : '240x400',
                    content : Utils.renderTemplate(self._const.entities + '-list', {
                        size : '240x400'
                    })
                },
                f300_250 : {
                    title   : '300x250',
                    content : Utils.renderTemplate(self._const.entities + '-list', {
                        size : '300x250'
                    })
                },
                f728_20 : {
                    title   : '728x90',
                    content : Utils.renderTemplate(self._const.entities + '-list', {
                        size : '728x90'
                    })
                }
            }
        });

        self._elems.$content.html(Utils.renderTemplate('banners', {
            nav     : nav,
            heading : heading,
            tabs    : tabs
        }));

        self._bindElements();
    };

    _init = () => {
        let self = this;

        self._bindElements();

        if (self._args.page == Vars.page.index) {
            self._index();
        }
    }
}

export default Banner;