export default {
    payouts : {
        methods : {
            'yandex.money' : {
                'name' : 'Яндекс.Деньги'
            }
        },
        statuses : {
            handling : {
                name : 'В обработке'
            },
            approved : {
                name : 'Исполнено'
            },
            rejected : {
                name : 'Отклонено'
            }
        }
    },
    revenues : {
        statuses : {
            frozen : {
                name : 'В ожидании'
            },
            accrued : {
                name : 'Зачислено'
            },
            cancelled : {
                name : 'Отменено. Возврат средств клиенту'
            }
        }
    }
}