import Request from './../../components/request';
import Validation from './../../classes/Validation';

import Vars from './../../vars';
import Utils from './../../components/utils';
import _NAV from './../Const/_NAV';

import Loader from './../../components/loader';

class Account {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();
    }

    _args = {
        page : ''
    };

    _elems = {
        $content : $(),
        $form    : $(),
        $cash    : $(),
        form     : {
            $auth    : $(),
            $reg     : $(),
            $remind  : $(),
            $restore : $()
        }
    };

    _state = {
        item     : {}
    };

    _setArgs = (args) => {
        let self = this;

        if (args) {
            self._args.page = args.page ? args.page : '';
        }
    };

    _bindForm = () => {
        let self = this;

        let validation = new Validation({
            form : self._elems.$form
        });

        self._elems.$form.on('init', function(e){
            e.preventDefault();

            let $form = $(this);

            $form.find('input').on('keyup', function(){
                $form.trigger('update');
            });
            $form.find('input').on('change', function(){
                $form.trigger('update');
            });
            $form.find('input').bind('DOMAutoComplete', function(){
                $form.trigger('update');
            });

            $form.trigger('update');
        });

        self._elems.$form.on('update', function(e){
            e.preventDefault();

            let $form = $(this);

            $form.children('.form-error').hide().remove();

            let $submit = $form.find('[type=submit]');
            if (!$submit.length) return;

            let count = {
                required : 0,
                filled   : 0
            };

            $form.find('input').each(function () {
                if ($(this).prop('required') !== undefined && $(this).attr('type') != 'hidden') {
                    count.required++;
                }
                if ($(this).val() != '' && $(this).attr('type') != 'hidden') {
                    count.filled++;
                }
            });

            if (count.filled < count.required) {
                //$submit.attr('disabled', 'disabled');
            }
            else {
                $submit.removeAttr('disabled');
            }
        });

        self._elems.$form.submit(function(e){
            e.preventDefault();
            let $form = $(this);

            let data = {};
            $form.find('input').each(function(){
                data[$(this).attr('name')] = $(this).val();
            });

            let action = $form.attr('action');
            if (self._args.page == Vars.page.index) {
                action = '/api/partners/edit';
            }

            Request.send({
                path   : action,
                data   : data,
                method : $form.attr('method'),
                done   : function(response) {
                    if (response.errors) {
                        validation.showErrors(response.errors);
                        return;
                    }
                    else if (response.error) {
                        let error = response.error
                        if (response.error.type == 'auth') {
                            error = response.error.text
                        }
                        if (!$form.children('.form-error').length) {
                            $form.append(
                                $('<div />')
                                    .addClass('form-error')
                            )
                        }
                        $form.children('.form-error').each(function() {
                            $(this).show().html(error);
                        });
                        return;
                    }

                    if (response.message) {
                        if (!$form.children('.form-message').length) {
                            $form.append(
                                $('<div />')
                                    .addClass('form-message')
                            )
                        }
                        $form.children('.form-message').each(function() {
                            $(this).show().html(response.message);
                        });
                    }
                    else if (response.url) {
                        window.location.href = response.url;
                    }
                    else {
                        window.location.reload();
                    }
                }
            })
        });

        self._elems.$form.trigger('init');

        self._elems.$content.find('[data-verify]').click(function(e){
            e.preventDefault();

            let $link = $(this);

            Loader.increase();
            Request.send({
                path : '/api/partners/verify',
                done : function(response){
                    Loader.decrease();
                    if (response.message) {
                        if (!$link.parent().next('.message').length) {
                            $link.parent().after(
                                $('<p />')
                                    .addClass('message')
                            )
                        }
                        $link.parent().next('.message').each(function() {
                            $(this).show().html(response.message);
                        });

                        setTimeout(function(){
                            $link.parent().next('.message').remove();
                        }, 10000);
                    }
                }
            })
        });
    };

    _bindData = () => {
        let self = this;

        let nav = Utils.renderTemplate('nav', {
            nav    : _NAV,
            active : 'account'
        });
        let heading = Utils.renderTemplate('heading', {
            title : 'Личные данные'
        });

        self._elems.$content.html(Utils.renderTemplate('account-form', {
            nav     : nav,
            heading : heading
        }));

        self._elems.$cash = $('.cash');
        self._elems.$cash.trigger('update');

        self._elems.$form = self._elems.$content.find('form');
        self._bindForm();
    };

    _bindAuth = () => {
        let self = this;
        if (!self._elems.form.$auth) return;

        self._elems.$form = self._elems.form.$auth;
        self._bindForm();
    };

    _bindReg = () => {
        let self = this;
        if (!self._elems.form.$reg) return;

        self._elems.$form = self._elems.form.$reg;
        self._bindForm();
    };

    _bindRemind = () => {
        let self = this;
        if (!self._elems.form.$remind) return;

        self._elems.$form = self._elems.form.$remind;
        self._bindForm();
    };

    _bindRestore = () => {
        let self = this;
        if (!self._elems.form.$restore) return;

        self._elems.$form = self._elems.form.$restore;
        self._bindForm();
    };

    _bindElements = () => {
        let self = this;

        self._elems.$content = !self._elems.$content.length ? $('.content') : self._elems.$content;
        if(!self._elems.$content.length) return;

        self._elems.form.$auth    = self._elems.$content.find('#form-auth');
        self._elems.form.$reg     = self._elems.$content.find('#form-reg');
        self._elems.form.$remind  = self._elems.$content.find('#form-remind');
        self._elems.form.$restore = self._elems.$content.find('#form-restore');

        self._bindUI();
    };

    _bindUI = () => {
        let self = this;

        if (self._args.page == Vars.page.index) {
            self._bindData();
        }
        else {
            self._bindAuth();
            self._bindReg();
            self._bindRemind();
            self._bindRestore();
        }
    };

    _init = () => {
        let self = this;

        self._bindElements();
    }
}

export default Account;