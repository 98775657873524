import Request from './../../components/request';
import Utils from './../../components/utils';
import Modal from "../../components/Modal";

class Cash {
    constructor(args) {
        let self = this;

        self._init(args);
    }

    _elems = {
        $_       : $(),
        $content : $()
    };

    _state = {
        item     : {}
    };

    _render = () => {
        let self = this;

        self._elems.$_ = $('<div />').addClass('cash');
        $('body').append(self._elems.$_);

        self._elems.$_.on('update', function(){
            self._update();
        });

        self._elems.$_.trigger('update');
    };

    _update = () => {
        let self = this;

        loader.increase();

        Request.send({
            path : '/api/partners/cash',
            done : function(response) {
                loader.decrease();
                self._elems.$_.addClass('--opened');

                self._elems.$_.html(Utils.renderTemplate('cash', {
                    accrued  : response.accrued,
                    frozen   : response.frozen,
                    handling : response.handling,
                    isAdmin  : response.is_admin
                }));
            }
        });
    };

    _init = () => {
        let self = this;

        self._render();
    }
}

export default Cash;