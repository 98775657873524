import $ from 'jquery';

window.jQuery = $;
window.$ = $;

import noUiSlider from 'nouislider';

require ('lazyload');

let multi_select  = require('./../../plugins/multi.select')

window.fn = multi_select;

import Vars from './../Vars';
import Modal from './../../components/Modal';
import Utils from './../../components/utils';
import Functions from './../components/functions';
import Tip from './../components/Tip';

class Catalog {
    constructor(args) {
        let self = this
        self._setArgs(args)
        self._init()
    };

    _const = {
        module : 'catalog'
    };

    _args = {
        widget    : {},
        plan      : {},
        entities  : {},
        constants : {},
        urlPrefix : '',
        isSale    : false,
        isRent    : false
    };

    _state = {
        widgetId   : 0,
        buildingId : 0,
        sectionId  : 0,
        floorId    : 0,
        filter     : {
            count : {
                top     : 0,
                timeout : false
            },
            withoutPrice : {
                exist  : false,
                active : false
            }
        },
        limit     : 12,
        page      : 1,
        urlPrefix : '',
        entities   : {},
        constants  : {},

        is_rendered : false,

        width     : null
    };

    _collect = {
        widget   : {},
        object   : {},
        building : {},
        section  : {},
        floor    : {},
        flats    : {
            base     : [],
            filtered : []
        },
        filter   : {
            ranges : [],
            params : {},
            pre    : {
                params : {}
            }
        },

        buildingIds : []
    };

    _elems = {
        $_        : $(),
        $widget   : $(),
        $filter   : $(),
        $tabs     : $(),
        $main     : $(),
        $pain     : $()
    };

    _setArgs = (args) => {
        let self = this;
        if (!args.$root || !args.$root.length) return;

        self._elems.$_    = args.$root;
        self._args.widget = args.widget ? args.widget : {};
        self._args.flats  = args.flats ? args.flats : [];

        self._args.isSale = args.isSale ? args.isSale : false;
        self._args.isRent = args.isRent ? args.isRent : false;

        self._args.constants = args.constants ? args.constants : {};
        self._args.urlPrefix = args.urlPrefix ? args.urlPrefix : '';

        self._args.withoutPrice = args.withoutPrice ? args.withoutPrice : false;
    };

    _bindElements = () => {
        let self = this;

        self._elems.$widget   = Object.keys(self._args.widget).length ? self._elems.$_ : $();
        self._elems.$filter   = self._elems.$widget.length ? self._elems.$_.find('.section-advanced').children('.advanced-filter') : self._elems.$_.prev('.section-advanced').children('.advanced-filter');
        self._elems.$tabs     = self._elems.$_.find('.section-main__tabs');
        self._elems.$main     = self._elems.$_.find('.section-main__content');
        self._elems.$pain     = self._elems.$_.find('.section-pain');
    };

    _bindState = () => {
        let self = this;

        self._state.urlPrefix = self._args.urlPrefix ? self._args.urlPrefix : '';
        self._state.constants = Object.keys(self._args.constants).length ? self._args.constants : ifacade.constants;

        self._args.constants = {};
    };

    _bindCollect = () => {
        let self = this;

        self._collect.widget = self._elems.$widget.length ? self._args.widget : {};
        self._collect.object = Object.keys(self._collect.widget).length ? self._collect.widget.object : ifacade.object;
        {
            self._state.buildingId = Object.keys(self._collect.widget).length ? 0 : (ifacade.buildingId ? ifacade.buildingId : 0);

            let buildings = Object.keys(self._collect.object).length && self._state.buildingId ? self._collect.object.buildings.filter(function(building){ return building.id == self._state.buildingId; }) : {};
            self._collect.building = Object.keys(buildings).length ? buildings[0] : {};
        }
        {
            self._state.sectionId = Object.keys(self._collect.widget).length ? 0 : (ifacade.sectionId ? ifacade.sectionId : 0);

            let sections = Object.keys(self._collect.building).length && self._state.sectionId ? self._collect.building.sections.filter(function(section){ return section.id == self._state.sectionId; }) : {};
            self._collect.section = Object.keys(sections).length ? sections[0] : {};
        }
        {
            self._state.floorId = Object.keys(self._collect.widget).length ? 0 : (ifacade.floorId ? ifacade.floorId : 0);

            let floors = Object.keys(self._collect.object).length && self._state.floorId ? self._collect.object.floors.filter(function(floor){ return floor.id == self._state.floorId; }) : {};
            self._collect.floor = Object.keys(floors).length ? floors[0] : {};
        }
        {
            self._collect.flats.base = self._args.flats;
            self._args.flats = [];
        }

        if (!self._collect.flats.base.length){
            Functions._collectFlats(self);
        }
    };

    _bindFilterBuildings = () => {
        let self = this;

        let $select = self._elems.$filter.find('.filter-item[data-name="building"] .multi-select');
        if (!$select.length) return;

        let buildings = self._collect.object.buildings;
        if (!Array.isArray(buildings)) return;
        if (!buildings.length) return;

        let selected  = buildings.map(b => b.id.toString());

        let buildingMap = {};
        let index = 0;
        buildings.sort(function(b1, b2){
            if(b1.number < b2.number) return -1;
            if(b1.number > b2.number) return 1;
            return 0;
        }).forEach(function(building){
            index++;
            buildingMap[index + '_' + building.id] = building.name != '' ? building.name : 'Дом №' + building.number;

            if (selected && selected.indexOf(building.id.toString()) != -1) {
                for (let key in selected) {
                    if (selected[key] == building.id) {
                        selected[key] = index + '_' + building.id;
                    }
                }
            }
        })

        $select.multi_select({
            data            : buildingMap,
            selectText      : 'Выбрать дом',
            selectedText    : 'Выбран дом',
            selectAllText   : 'Выбрать все',
            selectNoneText  : 'Убрать все',
            selectedIndexes : selected,
            onSelect        : function( data) {
                self._collect.buildingIds = []
                data.forEach(d => {
                    self._collect.buildingIds.push(parseInt(d.substr(d.indexOf('_') + 1)))
                })
                self._elems.$filter.trigger('update');
            }
        });
    };

    _bindFilterInteriors = () => {
        let self = this;

        let $select = self._elems.$filter.find('.filter-item[data-name="interior"] .multi-select');
        if (!$select.length) return;

        let interiorMap = {};
        const INTERIORS = ifacade.constants.flats.interiors;
        Object.keys(INTERIORS).forEach(i => {
            interiorMap[i] = INTERIORS[i].name
        })

        $select.multi_select({
            multiple        : false,
            data            : interiorMap,
            selectText      : 'Выбрать отделку',
            selectedText    : 'Выбрана отделка',
            selectAllText   : 'Выбрать все',
            selectNoneText  : 'Убрать все',
            selectedIndexes : [],
            onSelect        : function(data) {
                self._collect.filter.pre.params.interiors = self._collect.interiors = data
            }
        });
    };

    _bindFilterWindowViews = () => {
        let self = this;

        let $select = self._elems.$filter.find('.filter-item[data-name="window-view"] .multi-select');
        if (!$select.length) return;

        let windowViewMap = {
            windows_overlook_street : 'На улицу',
            windows_overlook_yard   : 'Во двор'
        };

        $select.multi_select({
            data            : windowViewMap,
            selectText      : 'Выбрать вид',
            selectedText    : 'Выбран вид',
            selectAllText   : 'Выбрать все',
            selectNoneText  : 'Убрать все',
            selectedIndexes : [],
            onSelect        : function( data) {
                self._collect.filter.pre.params.windowViews = self._collect.windowViews = data
            }
        });
    };

    _bindFilterStatuses = () => {
        let self = this;

        let $select = self._elems.$filter.find('.filter-item[data-name="status"] .multi-select');
        if (!$select.length) return;

        let statusMap = {};
        const STATUSES = ifacade.constants.flats.statuses;
        Object.keys(STATUSES).forEach(i => {
            if (self._collect.flats.base.filter(f => f.status == i).length) {
                statusMap[i] = STATUSES[i].name
            }
        })

        $select.multi_select({
            data            : statusMap,
            selectText      : 'Выбрать статус',
            selectedText    : 'Выбран статус',
            selectAllText   : 'Выбрать все',
            selectNoneText  : 'Убрать все',
            selectedIndexes : [],
            onSelect        : function( data) {
                self._collect.filter.pre.params.statuses = self._collect.statuses = data
            }
        });
    };

    _bindUI = () => {
        let self = this;
        if (!self._elems.$_.length) return;

        $(document).on('click', function(e){
            if (!$(e.target).closest('.filter-dropdown').length && !$(e.target).closest('.filter-item--dropdown a').length) {
                self._elems.$filter.find('.filter-dropdown').trigger('close');
            }
        });

        $(document).on('touchstart', function(e){
            if (!$(e.target).closest('.filter-dropdown').length && !$(e.target).closest('.filter-item--dropdown a').length) {
                self._elems.$filter.find('.filter-dropdown').trigger('close');
            }
        });

        $(document).ready(function(){
            self._state.width = $('body').innerWidth();
        });

        $(window).resize(function(){
            self._state.width = $('body').innerWidth();
            if (!(self._state.width >= $('body').innerWidth() - 5 && self._state.width <= $('body').innerWidth() && $('body').innerWidth() < 576)) {
                self._bindFilterSize();
            }
        });
    };

    _bindFilterSize = () => {
        let self = this;

        let i = 0;
        let y = 4;

        if (!self._state.width) {
            self._state.width = $('body').innerWidth();
        }

        if (self._state.width < 1200 - Functions.getScrollBarWidth() - 2) {
            y = 3;
        }

        const count = self._elems.$filter.find('.advanced-filter__columns[data-name="params"]').children().length;

        self._elems.$filter.find('.advanced-filter__columns[data-name="params"]').children().each(function(){
            $(this).removeClass('--minor');
            if ($(this).hasClass('--hidden')) return;
            if (i < y) {
                $(this).show().css({'z-index' : count + 20 - i});
            }
            else {
                $(this).addClass('--minor').css({'z-index' : count + 10 - i});
            }
            i++;
        });

        self._elems.$filter.find('.advanced-filter__wrapper').removeAttr('style');
    };

    _bindFilter = () => {
        let self = this;

        self._state.filter.withoutPrice.active = self._args.withoutPrice ? self._args.withoutPrice : false;
        if (self._args.withoutPrice) {
            self._elems.$filter.find('[data-name="prices"]').parents('.advanced-filter__column').remove();
            self._elems.$filter.find('input[name="filter_catalog_without_price"]').parents('.advanced-filter__column').remove();
        }

        self._bindFilterSize();

        self._elems.$filter.on('update', function(){
            self._updateFilter();
        });

        self._elems.$filter.find('.advanced-filter__button-minor').length && self._elems.$filter.find('.advanced-filter__button-minor').click(function(e){
            e.preventDefault();
            $(this).toggleClass('--active');

            if ($(this).hasClass('--active')) {
                self._elems.$filter.find('.advanced-filter__columns[data-name="params"]').children().each(function(){
                    if ($(this).hasClass('--hidden')) return;
                    $(this).show();
                });
            }
            else {
                let i = 0;
                let y = 4;

                if (self._state.width < 1200) {
                    y = 3;
                }

                self._elems.$filter.find('.advanced-filter__columns[data-name="params"]').children().each(function(){
                    if ($(this).hasClass('--hidden')) return;
                    if (i >= y) {
                        $(this).hide();
                    }
                    i++;
                });
            }
        });

        self._elems.$filter.find('.advanced-filter__button').length && self._elems.$filter.find('.advanced-filter__button').click(function(e){
            e.preventDefault();
            if ($(this).hasClass('--toggle')) {
                self._elems.$filter.find('.advanced-filter__wrapper').slideToggle();
            }
            else {
                self._collect.filter.params = JSON.parse(JSON.stringify(self._collect.filter.pre.params));

                self._state.page = 1;
                self._updateCatalog();
            }
        });

        if (!self._args.withoutPrice) {
            self._state.filter.withoutPrice.active = self._elems.$filter.find('input[name="filter_catalog_without_price"]').is(':checked');
        }

        self._elems.$filter.find('input[name="filter_catalog_without_price"]').change(function(){
            self._state.filter.count.top = $(this).offset().top - self._elems.$filter.offset().top;
            if ($(this).is(':checked')) {
                self._state.filter.withoutPrice.active = true;
            } else {
                self._state.filter.withoutPrice.active = false;
            }
        });

        let $areasRange = $('#ifacadeCatalogAreaRange');
        let areasRange = document.getElementById($areasRange.attr('id'));

        let $pricesRange = $('#ifacadeCatalogPriceRange');
        let pricesRange = document.getElementById($pricesRange.attr('id'));

        let $floorsRange = $('#ifacadeCatalogFloorRange');
        let floorsRange = document.getElementById($floorsRange.attr('id'));

        self._elems.$filter.find('[data-name="areas_from"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(value)) {
                if (value >= self._collect.filter.pre.params.areas.min && value <= self._collect.filter.pre.params.areas.max) {
                    areasRange.noUiSlider.updateOptions({
                        start : [Math.floor(value), Math.ceil(self._collect.filter.pre.params.areas.to)]
                    });
                }
                else {
                    self._collect.filter.pre.params.areas.from = self._collect.filter.pre.params.areas.min
                    areasRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(self._collect.filter.pre.params.areas.to)]
                    });
                }
            }
            else {
                areasRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(self._collect.filter.pre.params.areas.to)]
                });
            }
        });

        self._elems.$filter.find('[data-name="areas_from"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!areasRange.noUiSlider) return;

            areasRange.noUiSlider.updateOptions({
                start : [Math.floor(value), Math.ceil(self._collect.filter.pre.params.areas.to)]
            });
        });

        self._elems.$filter.find('[data-name="areas_to"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(value)) {
                if (value >= self._collect.filter.pre.params.areas.min && value <= self._collect.filter.pre.params.areas.max) {
                    areasRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(value)]
                    });
                }
                else {
                    self._collect.filter.pre.params.areas.to = self._collect.filter.pre.params.areas.max
                    areasRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(self._collect.filter.pre.params.areas.to)]
                    });
                }
            }
            else {
                areasRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(self._collect.filter.pre.params.areas.to)]
                });
            }
        });
        self._elems.$filter.find('[data-name="areas_to"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!areasRange.noUiSlider) return;

            areasRange.noUiSlider.updateOptions({
                start : [Math.floor(self._collect.filter.pre.params.areas.from), Math.ceil(value)]
            });
        });

        self._elems.$filter.find('[data-name="prices_from"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(parseFloat(value))) {
                if (value >= self._collect.filter.pre.params.prices.min && value <= self._collect.filter.pre.params.prices.max) {
                    pricesRange.noUiSlider.updateOptions({
                        start : [Math.floor(value), Math.ceil(self._collect.filter.params.prices.to)]
                    });
                }
                else {
                    self._collect.filter.pre.params.prices.from = self._collect.filter.pre.params.prices.min
                    pricesRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.prices.from), Math.ceil(self._collect.filter.pre.params.prices.to)]
                    });
                }
            }
            else {
                pricesRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.pre.params.prices.from), Math.ceil(self._collect.filter.pre.params.prices.to)]
                });
            }
        });
        self._elems.$filter.find('[data-name="prices_from"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!pricesRange.noUiSlider) return;

            pricesRange.noUiSlider.updateOptions({
                start : [Math.floor(value), Math.ceil(self._collect.filter.pre.params.prices.to)]
            });
        });

        self._elems.$filter.find('[data-name="prices_to"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(parseFloat(value))) {
                if (value >= self._collect.filter.pre.params.prices.min && value <= self._collect.filter.pre.params.prices.max) {
                    pricesRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.prices.from), Math.ceil(value)]
                    });
                }
                else {
                    self._collect.filter.pre.params.prices.to = self._collect.filter.pre.params.prices.max
                    pricesRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.params.prices.from), Math.ceil(self._collect.filter.params.prices.to)]
                    });
                }
            }
            else {
                pricesRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.params.prices.from), Math.ceil(self._collect.filter.params.prices.to)]
                });
            }
        });
        self._elems.$filter.find('[data-name="prices_to"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!pricesRange.noUiSlider) return;

            pricesRange.noUiSlider.updateOptions({
                start : [Math.floor(self._collect.filter.pre.params.prices.from), Math.ceil(value)]
            });
        });

        self._elems.$filter.find('[data-name="floors_from"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(parseFloat(value))) {
                if (value >= self._collect.filter.pre.params.floors.min && value <= self._collect.filter.pre.params.floors.max) {
                    floorsRange.noUiSlider.updateOptions({
                        start : [Math.floor(value), Math.ceil(self._collect.filter.pre.params.floors.to)]
                    });
                }
                else {
                    self._collect.filter.pre.params.floors.from = self._collect.filter.pre.params.floors.min
                    floorsRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.floors.from), Math.ceil(self._collect.filter.pre.params.floors.to)]
                    });
                }
            }
            else {
                floorsRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.pre.params.floors.from), Math.ceil(self._collect.filter.pre.params.floors.to)]
                });
            }
        });
        self._elems.$filter.find('[data-name="floors_from"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!floorsRange.noUiSlider) return;

            floorsRange.noUiSlider.updateOptions({
                start : [Math.floor(value), Math.ceil(self._collect.filter.pre.params.floors.to)]
            });
        });

        self._elems.$filter.find('[data-name="floors_to"]').unbind('focusout').on('focusout', function() {
            let $input = $(this);
            let value = parseFloat($input.val());
            $input.val(value)

            if (Number.isFinite(parseFloat(value))) {
                if (value >= self._collect.filter.pre.params.floors.min && value <= self._collect.filter.pre.params.floors.max) {
                    floorsRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.params.pre.floors.from), Math.ceil(value)]
                    });
                }
                else {
                    self._collect.filter.pre.params.floors.to = self._collect.filter.pre.params.floors.max
                    floorsRange.noUiSlider.updateOptions({
                        start : [Math.floor(self._collect.filter.pre.params.floors.from), Math.ceil(self._collect.filter.pre.params.floors.to)]
                    });
                }
            }
            else {
                floorsRange.noUiSlider.updateOptions({
                    start : [Math.floor(self._collect.filter.pre.params.floors.from), Math.ceil(self._collect.filter.pre.params.floors.to)]
                });
            }
        });
        self._elems.$filter.find('[data-name="floors_to"]').on('changeByFilter', function() {
            let value = $(this).val();

            if (!floorsRange.noUiSlider) return;

            floorsRange.noUiSlider.updateOptions({
                start : [Math.floor(self._collect.filter.pre.params.floors.from), Math.ceil(value)]
            });
        });

        self._elems.$filter.trigger('update');

        self._collect.filter.params = JSON.parse(JSON.stringify(self._collect.filter.pre.params));
    };

    _bindFilterRange = () => {
        let self = this;

        let $ranges = self._elems.$filter.find('.filter-item__range');

        /*** Массив для хранения Range ***/
        self._collect.filter.ranges = [];

        $ranges.each(function(){
            let $range = $(this);
            if ($range.hasClass('noUi-target')) return;

            const name  = $range.data('name');

            let range = document.getElementById($range.attr('id'))

            if (self._collect.filter.pre.params[name].min == 0 && self._collect.filter.pre.params[name].max == 0) {
                self._elems.$filter.find('[data-name="prices"]').parents('.advanced-filter__column').remove();
                self._elems.$filter.find('input[name="filter_catalog_without_price"]').parents('.advanced-filter__column').remove();
                self._bindFilterSize();
                return;
            }

            if (self._collect.filter.pre.params[name].min === self._collect.filter.pre.params[name].max) return

            noUiSlider.create(range, {
                range : {
                    min : self._collect.filter.pre.params[name].min,
                    max : self._collect.filter.pre.params[name].max
                },

                step    : $range.data('step'),
                start   : [self._collect.filter.pre.params[name].min, self._collect.filter.pre.params[name].max],
                connect : true
            });

            range.noUiSlider.on('update', function(values){
                const from = Math.floor(values[0]);
                const to   = Math.ceil(values[1]);

                self._elems.$filter.find('[data-name="' + name + '_from"]').val(from);
                self._elems.$filter.find('[data-name="' + name + '_to"]').val(to);

                self._collect.filter.pre.params[name].from = from
                self._collect.filter.pre.params[name].to = to
            })

            range.noUiSlider.on('change', function(values){
                const from = Math.floor(values[0]);
                const to   = Math.ceil(values[1]);
                self._elems.$filter.find('[data-name="' + name + '_from"]').val(from);
                self._elems.$filter.find('[data-name="' + name + '_to"]').val(to);

                self._collect.filter.pre.params[name].from = from
                self._collect.filter.pre.params[name].to = to
            })

            self._elems.$filter.find('[data-name="' + name + '_from"]').val(Math.floor(self._collect.filter.pre.params[name].min));
            self._elems.$filter.find('[data-name="' + name + '_to"]').val(Math.ceil(self._collect.filter.pre.params[name].max));
        });
    };

    _setFlatsFiltered = () => {
        let self = this;

        if (
            self._elems.$_.hasClass('ifacade-catalog')
            ||
            self._elems.$_.hasClass('ifacade-widget')
        ){
            self._collect.flats.filtered = [];

            self._collect.flats.filtered = self._collect.flats.filtered.concat(self._collect.flats.base.filter(function(flat) {
                return self._collect.filter.params.buildingIds.indexOf(flat.building_id) != -1;
            }).filter(function (flat) {
                return self._collect.filter.params.sectionIds.indexOf(flat.section_id) != -1;
            }));

            /*** Фильтрация помещений по типу объявления ***/
            self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                return (self._collect.filter.params.flatAdTypes.is_sale.active && flat.is_sale) || (self._collect.filter.params.flatAdTypes.is_rent.active && flat.is_rent);
            });

            /*** Фильтрация помещений по типу ***/
            if (Object.keys(self._collect.filter.params.flatTypes).filter(function(type){
                return self._collect.filter.params.flatTypes[type].active;
            }).length){
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1) {
                        if (Object.keys(self._collect.filter.params.flatTypes).map(function (type) {
                            if (self._collect.filter.params.flatTypes[type].active) return type;
                        }).indexOf(flat.count_of_rooms.toString()) != -1) {
                            return flat;
                        }
                    }
                    else if (flat.type == 'office') {
                        if (Object.keys(self._collect.filter.params.flatTypes).map(function (type) {
                            if (self._collect.filter.params.flatTypes[type].active) return type;
                        }).indexOf(flat.type) != -1) {
                            return flat;
                        }
                    }
                });
            }

            /*** Фильтрация помещений по этажности ***/
            self._elems.$filter.find('[data-name="floors_from"]').val(self._collect.filter.pre.params.floors.from).trigger('changeByFilter')
            self._elems.$filter.find('[data-name="floors_to"]').val(self._collect.filter.pre.params.floors.to).trigger('changeByFilter')

            if (self._collect.filter.params.floors && self._collect.filter.params.floors.from !== undefined && self._collect.filter.params.floors.to !== undefined){
                var floors = self._collect.object.floors.map(function(floor){
                    if (floor.number >= self._collect.filter.params.floors.from
                        &&
                        floor.number <= self._collect.filter.params.floors.to
                    ) return floor.id;
                });
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    return floors.indexOf(flat.floor_id) != -1;
                });
            }

            /*** Фильтрация помещений по площади ***/
            self._elems.$filter.find('[data-name="areas_from"]').val(self._collect.filter.pre.params.areas.from).trigger('changeByFilter')
            self._elems.$filter.find('[data-name="areas_to"]').val(self._collect.filter.pre.params.areas.to).trigger('changeByFilter')

            if (self._collect.filter.params.areas && (self._collect.filter.params.areas.from || self._collect.filter.params.areas.to)){
                if (self._collect.filter.params.areas.from)
                    self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                        return flat.total_area >= self._collect.filter.params.areas.from;
                    });
                if (self._collect.filter.params.areas.to)
                    self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                        return flat.total_area <= self._collect.filter.params.areas.to;
                    });
            }

            /*** Фильтрация помещений по стоимости ***/
            self._elems.$filter.find('[data-name="prices_from"]').val(self._collect.filter.pre.params.prices.from).trigger('changeByFilter')
            self._elems.$filter.find('[data-name="prices_to"]').val(self._collect.filter.pre.params.prices.to).trigger('changeByFilter')

            if (self._collect.filter.params.prices){
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    return (
                            self._collect.filter.params.flatAdTypes.is_sale.active
                            &&
                            (
                                (flat.price > 0 && flat.price >= self._collect.filter.params.prices.from && flat.price <= self._collect.filter.params.prices.to)
                                ||
                                (self._state.filter.withoutPrice.active && flat.price == 0)
                            )
                        )
                        || (
                            self._collect.filter.params.flatAdTypes.is_rent.active
                            &&
                            (
                                (
                                    flat.monthly_price > 0 && flat.monthly_price >= self._collect.filter.params.prices.from && flat.monthly_price <= self._collect.filter.params.prices.to
                                )
                                ||
                                (self._state.filter.withoutPrice.active && flat.monthly_price === 0)
                            )
                        );
                });
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    if (self._state.filter.withoutPrice.active) {
                        return (
                            self._collect.filter.params.flatAdTypes.is_sale.active && flat.price >= 0
                            ||
                            self._collect.filter.params.flatAdTypes.is_rent.active && flat.monthly_price >= 0
                        );
                    }
                    else {
                        return (self._collect.filter.params.flatAdTypes.is_sale.active && flat.price <= self._collect.filter.params.prices.to || self._collect.filter.params.flatAdTypes.is_rent.active && flat.monthly_price <= self._collect.filter.params.prices.to);
                    }
                });
            }

            /*** Фильтрация помещений по отделке ***/
            if (self._collect.filter.params.interiors && self._collect.filter.params.interiors.length) {
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    return  self._collect.filter.params.interiors.indexOf(flat.interior) != -1;
                });
            }

            /*** Фильтрация помещений по виду из окон ***/
            if (self._collect.filter.params.windowViews && self._collect.filter.params.windowViews.length) {
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    let isFiltering = false;
                    if (self._collect.filter.params.windowViews.indexOf('windows_overlook_street') != -1) {
                        isFiltering = flat.windows_overlook_street;
                    }
                    if (!isFiltering && self._collect.filter.params.windowViews.indexOf('windows_overlook_yard') != -1) {
                        isFiltering = flat.windows_overlook_yard;
                    }

                    return isFiltering;
                });
            }

            /*** Фильтрация помещений по статусу ***/
            if (self._collect.filter.params.statuses && self._collect.filter.params.statuses.length) {
                self._collect.flats.filtered = self._collect.flats.filtered.filter(function(flat){
                    return  self._collect.filter.params.statuses.indexOf(flat.status) != -1;
                });
            }

            self._collect.flats.filtered = self._collect.flats.filtered.sort(function(f1, f2){
                return (f1.status < f2.status) ? - 1 : (f1.status > f2.status) ? 1 : 0;
            });
        }
        else {
            self._collect.flats.filtered = self._collect.flats.base.filter(function(flat){
                if (Object.keys(self._args.plan).length) {
                    return flat.typical_plan_id == self._args.plan.id;
                }
                return true;
            });
            self._collect.filter.params = {
                flatAdTypes : {
                    is_sale : {
                        active : self._args.isSale
                    },
                    is_rent : {
                        active : self._args.isRent
                    }
                }
            }
        }

        if (!self._state.is_rendered && !self._collect.flats.filtered.length) {
            if (Object.keys(self._args.widget).length) {

            }
            else {
                self._elems.$_.parents('.ifacade-section').remove();
            }
            return;
        }

        self._state.is_rendered = true
    };

    _setFlatsFilteredCount = () => {
        let self = this;

        if (Functions._isMobileAny()) return;

        let $count = self._elems.$filter.next('.advanced-filter__count');

        if (!$count.length) return;
        if (!self._state.filter.count.top) return;

        clearTimeout(self._state.filter.count.timeout);

        self._state.filter.count.timeout = setTimeout(function() {
            $count.find('span').text(self._collect.flats.filtered.length);
            if (self._state.filter.count.top){
                $count.css({'top': self._state.filter.count.top - 25});
            }
            $count.stop().fadeIn();

            setTimeout(function(){
                $count.stop().fadeOut();
            }, 5000);
        }, 400);
    };

    _updateFilter = () => {
        let self = this;

        let buildings = [];
        let sections  = [];

        self._collect.filter.pre.params = {
            buildingIds : self._collect.buildingIds,
            sectionIds  : [],
            floors      : {
                min  : self._collect.filter.pre.params && self._collect.filter.pre.params.floors ? self._collect.filter.pre.params.floors.min : null,
                max  : self._collect.filter.pre.params && self._collect.filter.pre.params.floors ? self._collect.filter.pre.params.floors.max : null,
                from : self._collect.filter.pre.params && self._collect.filter.pre.params.floors ? self._collect.filter.pre.params.floors.from : null,
                to   : self._collect.filter.pre.params && self._collect.filter.pre.params.floors ? self._collect.filter.pre.params.floors.to : null
            },
            areas       : {
                min  : self._collect.filter.pre.params && self._collect.filter.pre.params.areas ? self._collect.filter.pre.params.areas.min : null,
                max  : self._collect.filter.pre.params && self._collect.filter.pre.params.areas ? self._collect.filter.pre.params.areas.max : null,
                from : self._collect.filter.pre.params && self._collect.filter.pre.params.areas ? self._collect.filter.pre.params.areas.from : null,
                to   : self._collect.filter.pre.params && self._collect.filter.pre.params.areas ? self._collect.filter.pre.params.areas.to : null
            },
            prices      : {
                min  : self._collect.filter.pre.params && self._collect.filter.pre.params.prices ? self._collect.filter.pre.params.prices.min : null,
                max  : self._collect.filter.pre.params && self._collect.filter.pre.params.prices ? self._collect.filter.pre.params.prices.max : null,
                from : self._collect.filter.pre.params && self._collect.filter.pre.params.prices ? self._collect.filter.pre.params.prices.from : null,
                to   : self._collect.filter.pre.params && self._collect.filter.pre.params.prices ? self._collect.filter.pre.params.prices.to : null
            },
            flatAdTypes : self._collect.filter.pre.params && self._collect.filter.pre.params.flatAdTypes ? self._collect.filter.pre.params.flatAdTypes : null,
            flatTypes   : self._collect.filter.pre.params && self._collect.filter.pre.params.flatTypes ? self._collect.filter.pre.params.flatTypes : null,

            interiors   : Object.assign([], self._collect.interiors),
            windowViews : Object.assign([], self._collect.windowViews)
        };

        let buildingIds = self._collect.filter.pre.params.buildingIds;
        let sectionIds  = self._collect.filter.pre.params.sectionIds;
        let floors      = self._collect.filter.pre.params.floors;
        let areas       = self._collect.filter.pre.params.areas;
        let prices      = self._collect.filter.pre.params.prices;
        let flatAdTypes = self._collect.filter.pre.params.flatAdTypes;
        let flatTypes   = self._collect.filter.pre.params.flatTypes;

        let $dropdowns = self._elems.$filter.find('.filter-item--dropdown');
        /*** Поиск ID выбранных домов и секций ***/
        $dropdowns.each(function(){
            let $dropdown      = $(this);
            let $dropdownItems = $dropdown.children('.filter-dropdown');
            let $dropdownItem  = $dropdownItems.children('.filter-dropdown__variant');
            const activeClass  = $dropdownItems.data('active');
            $dropdownItem.each(function(){
                let $item = $(this);
                if ($dropdown.data('entity') === undefined) {
                    $dropdown.data('entity', $item.data('entity'));
                }

                if ($item.data('entity') == 'building' && $item.hasClass(activeClass)) {
                    buildingIds.push($item.data('id'));
                }
                if ($item.data('entity') == 'section' && $item.hasClass(activeClass)) {
                    sectionIds.push($item.data('id'));
                }
            });
        });

        /*** Если не выбран ни один дом, подбор ID всех домов***/
        if (!buildingIds.length && self._collect.object.buildings) {
            buildingIds = self._collect.object.buildings.map(function(building){
                return building.id;
            });
        }

        /*** Подборка домов по их ID ***/
        buildings = self._collect.object.buildings ? self._collect.object.buildings.filter(function(building){
            return buildingIds.indexOf(building.id) !== -1;
        }) : [];

        /*** Если не выбран ни одна секция, подбор ID всех секций ***/
        if (!sectionIds.length && buildings.length) {
            buildings.forEach(function (building) {
                if (!building.sections) return;
                sectionIds
                    =
                    sectionIds.concat(
                        building.sections
                            .filter(function(section) {
                                return buildingIds.indexOf(section.building_id) !== -1;
                            }).map(function (section) {
                            return section.id;
                        })
                    );
            });
        }

        /*** Подборка секций по их ID ***/
        self._collect.object.buildings && self._collect.object.buildings.filter(function(building){
            return  buildingIds.indexOf(building.id) !== -1;
        }).forEach(function(building){
            if (!building.sections) return;
            sections = sections.concat(building.sections
                .filter(function (section) {
                    return sectionIds.indexOf(section.id) !== -1;
                }));
        });

        /*** Исключение секций из фильтра ***/
        $dropdowns.each(function(){
            let $dropdown = $(this);
            if ($dropdown.data('entity') == 'section'){
                let $dropdownItems  = $dropdown.children('.filter-dropdown');
                let $dropdownItem   = $dropdownItems.children('.filter-dropdown__variant');
                const activeClass   = $dropdownItems.data('active');
                const disabledClass = $dropdownItems.data('disabled');

                $dropdownItem.each(function(){
                    let $item = $(this);

                    $item.removeClass(disabledClass);
                    if (buildingIds.length && $item.data('building_id') !== undefined && buildingIds.indexOf($item.data('building_id')) == -1)
                        $item.removeClass(activeClass).addClass(disabledClass);
                });
            }
        });

        /*** Поиск доступных типов объявлений ***/
        if (flatAdTypes === null){
            flatAdTypes = {
                is_sale : {
                    exist  : false,
                    active : false
                },
                is_rent : {
                    exist  : false,
                    active : false
                }
            };
            sections.forEach(function(section){
                self._collect.flats.base.filter(function(flat){
                    if (Object.keys(self._args.plan).length) {
                        return flat.typical_plan_id == self._args.plan.id;
                    }
                    return true;
                }).filter(function(flat){
                    return flat.section_id == section.id;
                }).forEach(function(flat){
                    if (!flatAdTypes.is_sale.exist && flat.is_sale) {
                        flatAdTypes.is_sale = {
                            exist  : true,
                            active : true
                        };
                    }
                    if (!flatAdTypes.is_rent.exist && flat.is_rent) {
                        flatAdTypes.is_rent = {
                            exist  : true,
                            active : true
                        };
                    }
                });
            });

            if (flatAdTypes.is_sale.exist && flatAdTypes.is_sale.active && flatAdTypes.is_rent.exist) {
                flatAdTypes.is_rent.active = false;
            }
        }

        /*** Поиск доступных типов помещений ***/
        if (flatTypes === null){
            flatTypes = {};
            sections.forEach(function(section){
                self._collect.flats.base.filter(function(flat){
                    if (Object.keys(self._args.plan).length) {
                        return flat.typical_plan_id == self._args.plan.id;
                    }
                    return true;
                }).filter(function(flat){
                    return flat.section_id == section.id;
                }).filter(function(flat){
                    return flat.is_sale || flat.is_rent;
                }).forEach(function(flat){
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1) {
                        if (Object.keys(flatTypes).length && flatTypes[flat.count_of_rooms]){
                            flatTypes[flat.count_of_rooms].active   = flatTypes[flat.count_of_rooms].active || ((flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent)) ? true : false;
                            flatTypes[flat.count_of_rooms].disabled = flatTypes[flat.count_of_rooms].active || (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? false : true;
                        }
                        else {
                            flatTypes[flat.count_of_rooms] = {
                                exist    : true,
                                active   : (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? true : false,
                                disabled : (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? false : true
                            };
                        }
                    }
                    else if (flat.type == 'office'){
                        if (Object.keys(flatTypes).length && flatTypes.office){
                            flatTypes.office.active   = flatTypes.office.active || ((flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent)) ? true : false;
                            flatTypes.office.disabled = flatTypes.office.active || (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? false : true;
                        }
                        else {
                            flatTypes.office = {
                                exist    : true,
                                active   : (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? true : false,
                                disabled : (flatAdTypes.is_sale.active && flat.is_sale) || (flatAdTypes.is_rent.active && flat.is_rent) ? false : true
                            };
                        }
                    }
                });
            });
        }

        /*** Поиск нижнего и верхнего этажей ***/
        let allMinFloor = null;
        let allMaxFloor = null;

        sections.forEach(function(section){
            var min = Math.min.apply(null, self._collect.object.floors.filter(function(floor){
                return floor.section_id == section.id;
            }).filter(function(floor){
                return floor.type != 'technical';
            }).filter(function(floor){
                return floor.number != 1000;
            }).map(function(floor){
                return floor.number;
            }));
            var max = Math.max.apply(null, self._collect.object.floors.filter(function(floor){
                return floor.section_id == section.id;
            }).filter(function(floor){
                return floor.type != 'technical';
            }).filter(function(floor){
                return floor.number != 1000;
            }).map(function(floor){
                return floor.number;
            }));

            allMinFloor = floors.min = allMinFloor !== null ? Math.min(allMinFloor, min) : min;
            allMaxFloor = floors.max = allMaxFloor !== null ? Math.max(allMaxFloor, max) : max;
            floors.from = floors.from !== null ? Math.max(floors.from, allMinFloor) : allMinFloor;
            floors.to   = floors.max !== null ? Math.min(floors.to, allMaxFloor) : allMaxFloor;
        });

        /*** Поиск минимального и максимального площадей ***/
        let allMinArea = null;
        let allMaxArea = null;

        sections.forEach(function(section){
            var sFlats = self._collect.flats.base.filter(function(flat){
                if (Object.keys(self._args.plan).length) {
                    return flat.typical_plan_id == self._args.plan.id;
                }
                return true;
            }).filter(function(flat){
                return flat.section_id == section.id;
            });

            if (flatAdTypes.is_sale.active)
                sFlats = sFlats.filter(function(flat){
                    return flat.is_sale;
                });
            if (flatAdTypes.is_rent.active)
                sFlats = sFlats.filter(function(flat){
                    return flat.is_rent;
                });

            if (Object.keys(flatTypes).filter(function(type){
                return flatTypes[type].active;
            }).length){
                sFlats = sFlats.filter(function(flat){
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1) {
                        if (Object.keys(flatTypes).map(function (type) {
                            if (flatTypes[type].active)
                                return type;
                        }).indexOf(flat.count_of_rooms.toString()) != -1)
                            return flat;
                    }
                    else if (flat.type == 'office') {
                        if (Object.keys(flatTypes).map(function (type) {
                            if (flatTypes[type].active)
                                return type;
                        }).indexOf(flat.type) != -1)
                            return flat;
                    }
                });
            }

            var min = Math.min.apply(null, sFlats.map(function(flat){
                return flat.total_area;
            }));
            var max = Math.max.apply(null, sFlats.map(function(flat){
                return flat.total_area;
            }));

            if (!Number.isFinite(min)) {
                min = null;
            }
            if (!Number.isFinite(max)) {
                max = null;
            }

            if (min !== null) {
                allMinArea = areas.min = areas.from = allMinArea !== null ? Math.floor(Math.min(allMinArea, min)) : Math.floor(min);
            }
            if (max !== null) {
                allMaxArea = areas.max = areas.to = allMaxArea !== null ? Math.ceil(Math.max(allMaxArea, max)) : Math.ceil(max);
            }
        });

        /*** Поиск минимального и максимального стоимости ***/
        let allMinPrice = null;
        let allMaxPrice = null;

        sections.forEach(function(section){
            var sFlats = self._collect.flats.base.filter(function(flat){
                if (Object.keys(self._args.plan).length) {
                    return flat.typical_plan_id == self._args.plan.id;
                }
                return true;
            }).filter(function(flat){
                return flat.section_id == section.id;
            });

            if (flatAdTypes.is_sale.active)
                sFlats = sFlats.filter(function(flat){
                    return flat.is_sale;
                });
            if (flatAdTypes.is_rent.active)
                sFlats = sFlats.filter(function(flat){
                    return flat.is_rent;
                });

            if (Object.keys(flatTypes).filter(function(type){
                return flatTypes[type].active;
            }).length){
                sFlats = sFlats.filter(function(flat){
                    if (['flat', 'apartment'].indexOf(flat.type) !== -1) {
                        if (Object.keys(flatTypes).map(function (type) {
                            if (flatTypes[type].active)
                                return type;
                        }).indexOf(flat.count_of_rooms.toString()) != -1)
                            return flat;
                    }
                    else if (flat.type == 'office') {
                        if (Object.keys(flatTypes).map(function (type) {
                            if (flatTypes[type].active)
                                return type;
                        }).indexOf(flat.type) != -1)
                            return flat;
                    }
                });
            }

            /*** Проверка на наличие предложений без стоимости ***/
            self._state.filter.withoutPrice = {
                exist  : self._state.filter.withoutPrice || sFlats.filter(function(flat){ return (flatAdTypes.is_sale.active && flat.price == 0) || (flatAdTypes.is_rent.active && flat.monthly_price == 0) }).length ? true : false,
                active : self._state.filter.withoutPrice.active
            };

            var max = Math.max.apply(0, sFlats.map(function(flat){
                if (flatAdTypes.is_sale.active) return flat.price;
                else if (flatAdTypes.is_rent.active) return flat.monthly_price;
            }));
            var min = Math.min.apply(0, sFlats.filter(function(flat){
                if (flatAdTypes.is_sale.active) {
                    return flat.price > 0
                }
                else if (flatAdTypes.is_rent.active) {
                    return flat.monthly_price > 0
                }
            }).map(function(flat){
                if (flatAdTypes.is_sale.active) return flat.price;
                else if (flatAdTypes.is_rent.active) return flat.monthly_price;
            }));

            if (!Number.isFinite(min)) {
                min = null;

                if (prices.min != null) {
                    min = Math.min.apply(0, self._collect.flats.base.filter(f => f.section_id == section.id).filter(function(flat){
                        if (flatAdTypes.is_sale.active) {
                            return flat.price > 0
                        }
                        else if (flatAdTypes.is_rent.active) {
                            return flat.monthly_price > 0
                        }
                    }).map(function(flat){
                        if (flatAdTypes.is_sale.active) return flat.price;
                        else if (flatAdTypes.is_rent.active) return flat.monthly_price;
                    }))
                }
            }
            if (!Number.isFinite(max)) {
                max = null;

                if (prices.max != null) {
                    max = Math.max.apply(0, self._collect.flats.base.filter(f => f.section_id == section.id).map(function(flat){
                        if (flatAdTypes.is_sale.active) return flat.price;
                        else if (flatAdTypes.is_rent.active) return flat.monthly_price;
                    }))
                }
            }

            if (min !== null) {
                allMinPrice = prices.min = prices.from = allMinPrice !== null ? Math.min(allMinPrice, min) : min;
            }
            if (max !== null) {
                allMaxPrice = prices.max = prices.to = allMaxPrice !== null ? Math.max(allMaxPrice, max) : max;
            }
        });

        /*** Обновление данных о доступных типах помещений ***/
        if (Object.keys(flatTypes).length && self._elems.$filter.find('.filter-item[data-name="room"]').length) {
            let $filterItem           = self._elems.$filter.find('.filter-item[data-name="room"]');
            let $filterAdTypeVariants = $filterItem.find('.filter-item__variants[data-name="ad_types"]');
            let $filterRoomVariants   = $filterItem.find('.filter-item__variants[data-name="rooms"]');
            const activeClass         = $filterRoomVariants.data('active');
            const disabledClass       = $filterRoomVariants.data('disabled');

            if ($filterAdTypeVariants.children('.filter-item__variant').length){
                let $filterVariant = $filterAdTypeVariants.children('a');
                $filterVariant.addClass(disabledClass);
                Object.keys(flatAdTypes).forEach(function (flatAdType) {
                    $filterAdTypeVariants.children('a[data-name="' + flatAdType + '"]').removeClass(disabledClass);
                });
            }
            else {
                Object.keys(flatAdTypes).forEach(function (flatAdType) {
                    let name = '';
                    if (flatAdType == 'is_sale') {
                        name = 'Продажа';
                    }
                    else if (flatAdType == 'is_rent') {
                        name = 'Аренда';
                    }

                    let $filterVariant
                        =
                        $('<div />')
                            .addClass('filter-item__variant')
                            .attr('data-name', flatAdType)
                            .append(
                                $('<input />')
                                    .attr('type', 'radio')
                                    .attr('name', 'filter_catalog_ad_type')
                                    .attr('id', 'filter_catalog_ad_type_' + flatAdType)
                                    .val(flatAdType)
                            )
                            .append(
                                $('<label />')
                                    .attr('for', 'filter_catalog_ad_type_' + flatAdType)
                                    .text(name)
                            );

                    if (Object.keys(flatAdTypes).filter(function(flatAdType){
                        return flatAdTypes[flatAdType].exist;
                    }).indexOf(flatAdType) != -1) {
                        $filterVariant.addClass(activeClass).removeClass(disabledClass);
                        $filterVariant.children('input').removeAttr('disabled');
                    }
                    else {
                        $filterVariant.removeClass(activeClass).addClass(disabledClass);
                        $filterVariant.children('input').attr('disabled', 'disabled').prop('checked', false);
                    }

                    $filterAdTypeVariants.append($filterVariant);

                    $filterAdTypeVariants.children().eq(0).find('input').trigger('click');
                });

                $filterAdTypeVariants.find('input').on('change', function(e){
                    e.preventDefault();

                    let checked = $filterAdTypeVariants.find('input:checked').val();
                    self._collect.filter.pre.params.flatAdTypes.is_sale.active = checked == 'is_sale';
                    self._collect.filter.pre.params.flatAdTypes.is_rent.active = checked == 'is_rent';
                    self._collect.filter.params.flatAdTypes.is_sale.active = checked == 'is_sale';
                    self._collect.filter.params.flatAdTypes.is_rent.active = checked == 'is_rent';

                    self._collect.filter.pre.params.flatTypes = undefined;

                    self._state.filter.count.top = $(this).offset().top - self._elems.$filter.offset().top;

                    self._elems.$filter.trigger('update');
                    $.when(self._setFlatsFiltered()).then(function() {
                        self._setFlatsFilteredCount();
                    });
                });
            }

            if ($filterRoomVariants.children('.filter-item__variant').length){
                let $filterVariant = $filterRoomVariants.children('a');
                $filterVariant.addClass(disabledClass).removeClass(activeClass);
                Object.keys(flatTypes).forEach(function (flatType) {
                    $filterRoomVariants.children('a[data-name="' + flatType + '"]').removeClass(disabledClass);
                    if (flatTypes[flatType].active) {
                        $filterRoomVariants.children('a[data-name="' + flatType + '"]').addClass(activeClass);
                    }
                    else if (flatTypes[flatType].disabled) {
                        $filterRoomVariants.children('a[data-name="' + flatType + '"]').addClass(disabledClass);
                    }
                });
            }
            else {
                const fourUpRooms = Object.keys(flatTypes).filter(fa => fa >= 4).length
                Object.keys(flatTypes).filter(fa => fa < 4).forEach(function (flatType) {
                    var name = '';
                    if (flatType == 'office') {
                        name = 'Коммерческая';
                    }
                    else if (flatType == 0) {
                        name = '<span class="full">Студия</span><span class="short">Ст</span>';
                    }
                    else {
                        name = flatType;
                    }

                    let $filterVariant
                        =
                        $('<a />')
                            .attr('href', '#')
                            .addClass('filter-item__variant')
                            .attr('data-name', flatType)
                            .html(name);

                    if (flatType == 0) {
                        $filterVariant.addClass('--is-studio');
                    }

                    if (Object.keys(flatTypes).filter(function(fa){
                        return flatTypes[fa].active;
                    }).indexOf(flatType) != -1) {
                        $filterVariant.addClass(activeClass);
                    } else {
                        $filterVariant.addClass(disabledClass);
                    }

                    $filterRoomVariants.append($filterVariant);
                });

                if (fourUpRooms) {
                    let $filterVariant
                        =
                        $('<a />')
                            .attr('href', '#')
                            .addClass('filter-item__variant')
                            .attr('data-name', 4)
                            .text('4+');

                    if (Object.keys(flatTypes).filter(fa => flatTypes[fa].active).indexOf('4') != -1) {
                        $filterVariant.addClass(activeClass);
                    } else {
                        $filterVariant.addClass(disabledClass);
                    }

                    $filterRoomVariants.append($filterVariant);
                }

                $filterRoomVariants.children('a').on('click', function(e){
                    e.preventDefault();

                    if ($(this).hasClass(disabledClass)) return;

                    if ($(this).hasClass(activeClass)) {
                        $(this).removeClass(activeClass);
                        self._collect.filter.pre.params.flatTypes[$(this).data('name')].active = false;

                        if ($(this).data('name') == 4) {
                            Object.keys(self._collect.filter.pre.params.flatTypes).forEach(function(key){
                                if (key > 4) {
                                    self._collect.filter.pre.params.flatTypes[key].active = false;
                                }
                            })
                        }
                    } else {
                        $(this).addClass(activeClass);
                        self._collect.filter.pre.params.flatTypes[$(this).data('name')].active = true;

                        if ($(this).data('name') == 4) {
                            Object.keys(self._collect.filter.pre.params.flatTypes).forEach(function(key){
                                if (key > 4) {
                                    self._collect.filter.pre.params.flatTypes[key].active = true;
                                }
                            })
                        }
                    }

                    self._state.filter.count.top = $(this).offset().top - self._elems.$filter.offset().top;
                    self._elems.$filter.trigger('update');
                });
            }
        }

        /*** Обновление данных об этажах ***/
        let $floorsRange = $('#ifacadeCatalogFloorRange');
        if (floors.min !== undefined && floors.max !== undefined && floors.min == floors.max) {
            $floorsRange.hide();

            let $roomItem = self._elems.$filter.find('.filter-item[data-name="floor"]');
            if ($roomItem.length){
                $roomItem.find('[data-name="floors_from"]').val(Math.floor(floors.min));
                $roomItem.find('[data-name="floors_to"]').val(Math.ceil(floors.max))
            }
        }
        else if (floors.min !== undefined && floors.max !== undefined && $floorsRange.length && $floorsRange.hasClass('noUi-target')) {
            $floorsRange.show();
            let floorsRange = document.getElementById($floorsRange.attr('id'));

            floorsRange.noUiSlider.updateOptions({
                range: {
                    min : Math.floor(floors.min),
                    max : Math.ceil(floors.max)
                },
                start : [Math.floor(floors.min), Math.ceil(floors.max)]
            });

            let $roomItem = self._elems.$filter.find('.filter-item[data-name="floor"]');
            if ($roomItem.length){
                $roomItem.find('[data-name="floors_from"]').val(Math.floor(floors.min));
                $roomItem.find('[data-name="floors_to"]').val(Math.ceil(floors.max))
            }
        }
        if (floors.min !== undefined && floors.max !== undefined && Object.keys(self._collect.filter.ranges).length  && self._collect.filter.ranges.floors) {
            self._collect.filter.ranges.floors.update({
                min  : floors.min,
                max  : floors.max,
                from : Math.max(floors.min, floors.from),
                to   : Math.min(floors.max, floors.to)
            });
        }

        /*** Обновление данных о площадях ***/
        let $areasRange = $('#ifacadeCatalogAreaRange');
        if (areas.min && areas.max && areas.min == areas.max) {
            $areasRange.hide();

            let $roomItem = self._elems.$filter.find('.filter-item[data-name="area"]');
            if ($roomItem.length){
                $roomItem.find('[data-name="areas_from"]').val(Math.floor(areas.min));
                $roomItem.find('[data-name="areas_to"]').val(Math.floor(areas.max))
            }
        }
        else if (areas.min && areas.max && $areasRange.length && $areasRange.hasClass('noUi-target')) {
            $areasRange.show();
            let areasRange = document.getElementById($areasRange.attr('id'));

            areasRange.noUiSlider.updateOptions({
                range: {
                    min : Math.floor(areas.min),
                    max : Math.ceil(areas.max)
                },
                start : [Math.max(Math.floor(areas.min), Math.floor(areas.from)), Math.min(Math.ceil(areas.max),Math.ceil(areas.to))]
            });

            let $roomItem = self._elems.$filter.find('.filter-item[data-name="area"]');
            if ($roomItem.length){
                $roomItem.find('[data-name="areas_from"]').val(Math.floor(Math.max(Math.floor(areas.min), Math.floor(areas.from))));
                $roomItem.find('[data-name="areas_to"]').val(Math.ceil(Math.min(Math.ceil(areas.max),Math.ceil(areas.to))))
            }
        }

        /*** Обновление данных о стоимости ***/
        let $pricesRange = $('#ifacadeCatalogPriceRange');
        if (prices.min && prices.max && prices.min === prices.max) {
            $pricesRange.hide();

            let $priceItem = self._elems.$filter.find('.filter-item[data-name="price"]');
            if ($priceItem.length){
                $priceItem.find('[data-name="prices_from"]').val(Math.floor(prices.min));
                $priceItem.find('[data-name="prices_to"]').val(Math.ceil(prices.max))
            }
        }
        else if ($pricesRange.length && $pricesRange.hasClass('noUi-target')) {
            $pricesRange.show();
            let pricesRange = document.getElementById($pricesRange.attr('id'));

            pricesRange.noUiSlider.updateOptions({
                range: {
                    min : Math.floor(prices.min),
                    max : Math.ceil(prices.max)
                },
                start : [Math.max(Math.floor(prices.min), Math.floor(prices.from)), Math.min(Math.ceil(prices.max),Math.ceil(prices.to))]
            });
        }

        self._collect.filter.pre.params = {
            buildingIds : buildingIds,
            sectionIds  : sectionIds,
            floors      : floors,
            areas       : areas,
            prices      : prices,
            flatAdTypes : flatAdTypes,
            flatTypes   : flatTypes,

            interiors   : Object.assign([], self._collect.interiors),
            windowViews : Object.assign([], self._collect.windowViews)
        };

        self._bindFilterRange();
    };

    _bindCatalog = () => {
        let self = this;

        self._updateCatalog();
    };

    _updateCatalog = () => {
        let self = this;

        $.when(self._setFlatsFiltered()).then(function() {
            self._setFlatsFilteredCount();
        });

        /*** Вывод общего количества предложений ***/
        const value = '<strong>' + self._collect.flats.filtered.length + '</strong>&nbsp;' + Functions._wordforms(self._collect.flats.filtered.length, ['предложение', 'предложения', 'предложений']);
        self._elems.$_.find('.section-main__count').html(value);

        /*** Вывод постраничной навигации ***/
        if (self._collect.flats.filtered.length > self._state.limit) {
            let $pagination = self._elems.$_.find('.section-main__pagination');
            $pagination.html(Utils.renderTemplate('catalog-pagination', {limit : self._state.limit, count: self._collect.flats.filtered.length, page : self._state.page})).show();
            $pagination.find('a').click(function(e){
                e.preventDefault();

                self._state.page = parseInt($(this).data('page'));
                self._elems.$_.scrollTop(0);
                let top = $pagination.prev('.section-main__content').offset().top;
                if (!Object.keys(self._collect.widget).length) {
                    top -= 77;
                }
                $('html, body').animate({
                    scrollTop: top
                }, 500);
                self._updateCatalog();
            });
        }
        else {
            self._elems.$_.find('.section-main__pagination').hide();
        }

        /*** Плиточный вывод помещений ***/
        let $tile = self._elems.$_.find('.view-tile');
        if ($tile.length){
            $tile.empty();

            self._collect.flats.filtered.slice(self._state.page * self._state.limit - self._state.limit, self._state.page * self._state.limit).forEach(function(flat){
                $tile.append(
                    Utils.renderTemplate('catalog-tile-flat', {
                        building : self._collect.object.buildings.filter(function(building){ return building.id == flat.building_id; }),
                        flat     : flat,
                        is_sale  : self._collect.filter.params.flatAdTypes.is_sale.active,
                        is_rent  : self._collect.filter.params.flatAdTypes.is_rent.active,
                        widget   : self._collect.widget
                    })
                )
            });

            //$tile.find('img').lazyload();
        }

        /*** Табличный вывод помещений ***/
        let $list = self._elems.$_.find('.view-list');
        if ($list.length){
            $list.empty();
            $list.append(Utils.renderTemplate('catalog-list-blocks-flat'));
            $list.append(Utils.renderTemplate('catalog-list-table-flat'));

            let $block = $list.children('.content-view__list');
            let $table = $list.children('.content-view__table');

            self._collect.flats.filtered.slice(self._state.page * self._state.limit - self._state.limit, self._state.page * self._state.limit).forEach(function(flat){
                let building = self._collect.object.buildings.filter(function(building){return building.id == flat.building_id})[0];
                let section  = building.sections.filter(function(section){return section.id == flat.section_id})[0];
                let floor    = self._collect.object.floors.filter(function(floor){return floor.id == flat.floor_id})[0];

                $block.append(
                    Utils.renderTemplate('catalog-list-block-flat', {
                        flat     : flat,
                        floor    : floor,
                        section  : section,
                        building : building,
                        is_sale  : self._collect.filter.params.flatAdTypes.is_sale.active,
                        is_rent  : self._collect.filter.params.flatAdTypes.is_rent.active
                    })
                );
                $table.children('tbody').append(
                    Utils.renderTemplate('catalog-list-tr-flat', {
                        flat     : flat,
                        floor    : floor,
                        section  : section,
                        building : building,
                        is_sale  : self._collect.filter.params.flatAdTypes.is_sale.active,
                        is_rent  : self._collect.filter.params.flatAdTypes.is_rent.active
                    })
                )
            });
        }

        self._elems.$_.find('.flat-mark').on('fav', function(){
            const flatId = $(this).data('id');
            if ($(this).hasClass('--marked')) {
                self._collect.flats.base.filter(function(flat){
                    return flat.id == flatId;
                }).forEach(function(flat){ flat.marked = true; });
            }
            else {
                self._collect.flats.base.filter(function(flat){
                    return flat.id == flatId;
                }).forEach(function(flat){ flat.marked = false; })
            }
        })

        self._bindTileEvents();
        self._bindBlockEvents();
        self._bindTableEvents();
    };

    _bindTileEvents = () => {
        let self = this;

        let $tile = self._elems.$_.find('.view-tile');
        if (!$tile.length) return;

        self._bindTileClicks({
            $tile : $tile
        });
    };

    _bindTileClicks = (args) => {
        let self = this;
        let $tile = args.$tile;

        let $link  = $tile.find('.view-tile-item__link');
        if (!$link.length) return;

        $link.click(function(e){
            e.preventDefault();

            self._bindClick({
                $link : $(this)
            });
        });
    };

    _bindBlockEvents = () => {
        let self = this;

        let $block = self._elems.$_.find('.content-view__list');
        if (!$block.length) return;

        self._bindBlockClicks({
            $block : $block
        });

        self._bindBlockHovers({
            $block : $block
        });
    };

    _bindBlockClicks = (args) => {
        let self = this;
        let $block = args.$block;

        let $link  = $block.find('.content-view--list__item');
        if (!$link.length) return;

        $link.click(function(e){
            e.preventDefault();

            self._bindClick({
                $link : $(this)
            });
        });
    };

    _bindBlockHovers = (args) => {
        let self = this;
        let $block = args.$block;

        let $link  = $block.find('.content-view--list__item');
        if (!$link.length) return;

        if (Functions._isMobileAny()) return;

        $link.hover(function(e){
            e.preventDefault();

            self._bindHover({
                $link : $(this)
            });
        }, function() {
            window.tipWindow && tipWindow.close();
        });
    };

    _bindTableEvents = () => {
        let self = this;

        let $table = self._elems.$_.find('.content-view__table');
        if (!$table.length) return;

        self._bindTableClicks({
            $table : $table
        });

        self._bindTableHovers({
            $table : $table
        });
    };

    _bindTableClicks = (args) => {
        let self = this;
        let $table = args.$table;

        let $link  = $table.find('tr');
        if (!$link.length) return;

        $link.click(function(e){
            e.preventDefault();

            self._bindClick({
                $link : $(this)
            });
        });
    };

    _bindTableHovers = (args) => {
        let self = this;
        let $table = args.$table;

        let $link  = $table.find('tr');
        if (!$link.length) return;

        $link.hover(function(e){
            e.preventDefault();

            self._bindHover({
                $link : $(this)
            });
        }, function() {
            window.tipWindow && tipWindow.close();
        });
    };

    _bindClick = (args) => {
        let self = this;
        let $link = args.$link;

        if ($link.hasClass('disabled')) return;
        $link.addClass('disabled');

        const flatId = parseInt($link.data('id'));
        if (!flatId) return;

        let flats = self._collect.flats.base.filter(function(f){
            return flatId == f.id;
        });
        if (!flats.length) return;
        let flat = flats[0];

        let buildings = self._collect.object.buildings.filter(function(building){ return building.id == flat.building_id; });
        if (!buildings.length) return;
        let building = buildings[0];

        let sections  = building.sections.filter(function(section){ return section.id == flat.section_id; });
        if (!sections.length) return;
        let section = sections[0];

        let floors    = self._collect.object.floors.filter(function(floor){
            return floor.section_id == section.id;
        }).filter(function(floor){return floor.id == flat.floor_id});
        if (!floors.length) return;
        let floor = floors[0];

        new Modal({
            isProfile : false,
            trigger   : $link,
            type      : Vars.modal.types.flat,
            widget    : self._collect.widget,
            building  : building,
            section   : section,
            floor     : floor,
            flat      : flat
        });
    };

    _bindHover = (args) => {
        let self = this;
        let $link = args.$link;

        if (Functions._isMobileAny()) return;

        const flatId = parseInt($link.data('id'));
        if (!flatId) return;

        let flats = self._collect.flats.base.filter(function(f){
            return flatId == f.id;
        });
        if (!flats.length) return;
        let flat = flats[0];

        let buildings = self._collect.object.buildings.filter(function(building){ return building.id == flat.building_id; });
        if (!buildings.length) return;
        let building = buildings[0];

        let sections  = building.sections.filter(function(section){ return section.id == flat.section_id; });
        if (!sections.length) return;
        let section = sections[0];

        let floors    = self._collect.object.floors.filter(function(floor){
            return floor.section_id == section.id;
        }).filter(function(floor){return floor.id == flat.floor_id});
        if (!floors.length) return;
        let floor = floors[0];

        new Tip({
            type     : Vars.tip.types.flat,
            widget   : self._collect.widget,
            building : building,
            section  : section,
            floor    : floor,
            flat     : flat,
            isSale   : self._elems.$_.hasClass('ifacade-catalog') || self._elems.$_.hasClass('ifacade-widget') ? self._collect.filter.params.flatAdTypes.is_sale.active : self._args.isSale,
            isRent   : self._elems.$_.hasClass('ifacade-catalog') || self._elems.$_.hasClass('ifacade-widget') ? self._collect.filter.params.flatAdTypes.is_rent.active : self._args.isSale
        });
    };

    _bindTabs = () => {
        let self = this;

        let $tab = self._elems.$tabs.children('.section-main__tab');
        if (!$tab) return false;

        self._elems.$pain.hide();
        self._elems.$_.find('.section-pain[data-name="list"]').fadeIn();

        $tab.click(function(e){
            e.preventDefault();

            $tab.removeClass(self._elems.$tabs.data('active'));
            $(this).addClass(self._elems.$tabs.data('active'));

            self._elems.$pain.hide();
            self._elems.$_.find('.section-pain[data-name="' + $(this).data('target') + '"]').fadeIn();
        });
    };

    _render = () => {
        let self = this;

        if (!Array.isArray(self._collect.object.flats)) return;

        if (self._elems.$filter.length) {
            self._bindFilter();
        }
        if (self._elems.$tabs.length) {
            self._bindTabs();
        }
        self._bindCatalog();
    };

    _init = () => {
        let self = this;

        self._bindElements();
        self._bindState();
        self._bindCollect();
        self._bindFilterBuildings();
        self._bindFilterInteriors();
        self._bindFilterWindowViews();
        self._bindFilterStatuses();
        self._bindUI();
        self._render();
    }
}

export default Catalog;