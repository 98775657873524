export default {
    tip : {
        types : {
            building : 'building',
            section  : 'section',
            floor    : 'floor',
            flat     : 'flat',
            plan     : 'plan'
        }
    },
    modal : {
        types : {
            flat  : 'flat',
            plan  : 'plan',
            flats : 'flats',
            fav   : 'fav'
        }
    },
    flat : {
        types : {
            apartments : 'apartments',
            offices    : 'offices',
            all        : 'all'
        }
    },
    map : {
        services : {
            yandex : 'yandex'
        },
        types : {
            buildings : 'buildings'
        }
    }
};