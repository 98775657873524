import $ from 'jquery';

window.jQuery = $;
window.$ = $;

require ('jquery-mask-plugin');
require ('@fancyapps/fancybox');

import Vars from './../Vars';
import Request from './../../components/request';
import Utils from './../../components/utils';

// TODO Подключить Catalog2019 для темы 2019
import Catalog from './../modules/Catalog';

class Modal {
    constructor(args) {
        let self = this;

        self._setArgs(args);
        self._init();

        return self._elems.$_;
    };

    _args = {
        widget  : {},
        type    : ''
    };

    _elems = {
        $_       : $(),
        $parent  : $(),
        $close   : $(),
        $trigger : $(),
        $form    : $()
    };

    _state = {
        pop : false
    };

    _setArgs = (args) => {
        let self = this;

        self._args.widget  = args.widget;
        self._args.type    = args.type;

        self._elems.$trigger = args.trigger ? args.trigger : $();

        if (self._args.type == Vars.modal.types.flat) {
            self._args.object   = args.object;
            self._args.building = args.building;
            self._args.section  = args.section;
            self._args.floor    = args.floor;
            self._args.flat     = args.flat;
            self._args.closing  = args.closing;
        }
        else if (self._args.type == Vars.modal.types.plan) {
            self._args.plan   = args.plan;
            self._args.flats  = args.flats;
            self._args.isSale = args.isSale;
            self._args.isRent = args.isRent;
        }
        else if (self._args.type == Vars.modal.types.flats) {
            self._args.plan      = args.plan;
            self._args.isSale    = args.isSale;
            self._args.isRent    = args.isRent;
            self._args.constants = args.constants;

            self._elems.$parent = args.parent;
        }
        else if (self._args.type == Vars.modal.types.fav) {
            self._args.flats   = args.flats;
            self._args.isSale  = args.isSale;
            self._args.isRent  = args.isRent;
            self._args.closing = args.closing;
        }
    };

    _bindElements = () => {
        let self = this;

        if (self._args.type == Vars.modal.types.flats) {
            self._elems.$close = self._elems.$_.find('.section-main__close');
        }
        else {
            self._elems.$close = self._elems.$_.find('.ifacade-section__close');
        }
        self._elems.$form  = Object.keys(self._args.widget).length ? $('.section-widget-' + self._args.widget.widget_id + ' .ifacade-section--form') : $('.ifacade-section--form:not(.callback)');
    };

    _bindUI = () => {
        let self = this;

        self._elems.$_.on('open', function () {
            self._opening();
        });

        self._elems.$_.on('close', function () {
            self._closing();
        });

        self._elems.$close.length && self._elems.$close.click(function(e){
            e.preventDefault();
            self._close();
        });

        addEventListener('popstate',function(e) {
            self._state.pop = true;
            self._close();
        });
    };

    _render = () => {
        let self = this;

        if (self._args.type == Vars.modal.types.flats) {
            self._elems.$parent.find('section-main section-main--modal').remove();
            self._elems.$_ =
                $('<div />')
                    .addClass('section-main section-main--modal')
                    .data('opened', 'section-main--opened')
                    .html(Utils.renderTemplate('flat-modal-content'));

            self._elems.$parent.find('.section-main').append(self._elems.$_);

            new Catalog({
                $root     : self._elems.$_,
                flats     : self._args.plan.flats,
                plan      : self._args.plan,
                isSale    : self._args.isSale,
                isRent    : self._args.isRent,
                widget    : self._args.widget,
                constants : self._args.constants
            });
        }
        else if (self._args.type == Vars.modal.types.fav) {
            self._elems.$_ =
                $('<div />')
                    .addClass('ifacade-section-modal ifacade-section-modal--favourite')
                    .data('opened', 'ifacade-section-modal--opened')
                    .html(Utils.renderTemplate('modal'));

            self._elems.$_.appendTo('body');

            let $container = self._elems.$_.children('.ifacade-container');
            if (!$container.length) return;

            $(Utils.renderTemplate('favourite-flats-modal', {
                flats: 				self._args.flats
            })).appendTo($container);

            new Catalog({
                $root  : self._elems.$_,
                flats  : self._args.flats,
                isSale : self._args.isSale,
                isRent : self._args.isRent,
                widget : self._args.widget
            });
        }
        else {
            self._elems.$_ =
                $('<div />')
                    .addClass('ifacade-section-modal')
                    .data('opened', 'ifacade-section-modal--opened')
                    .html(Utils.renderTemplate('modal'));

            if (Object.keys(self._args.widget).length) {
                let $wrapper =
                    $('<div />')
                        .addClass('ifacade-section--widget section-widget-' + self._args.widget.widget_id)
                        .attr('id', 'ifacade-section-widget-' + self._args.widget.widget_id)
                        .appendTo('body');

                self._elems.$_.appendTo($wrapper);
            } else {
                self._elems.$_.appendTo('body');
            }
        }

        if (self._args.type == Vars.modal.types.flat) {
            self._bindFlat();
        }
        else if (self._args.type == Vars.modal.types.plan) {
            self._bindPlan();
        }
    };

    _open = () => {
        let self = this;

        self._elems.$_.trigger('open');
    };

    _opening = () => {
        let self = this;

        self._elems.$_.addClass(self._elems.$_.data('opened'));

        if (self._args.type != Vars.modal.types.flats) {
            $('body').css({'overflow': 'hidden'});
        }
    };

    _close = () => {
        let self = this;

        self._elems.$_.trigger('close');
    };

    _closing = () => {
        let self = this;

        self._elems.$_.removeClass(self._elems.$_.data('opened'));
        $('body').css({'overflow': 'initial'});

        if (self._elems.$trigger.length) self._elems.$trigger.removeClass('disabled');

        if (!self._state.pop) {
            self._args.closing && self._args.closing();
        }

        setTimeout(function(){
            if (Object.keys(self._args.widget).length && !self._elems.$_.hasClass('section-main--modal')) {
                self._elems.$_.parent().remove();
            }
            else {
                self._elems.$_.remove();
            }

            if ($(this).hasClass('ifacade-section-modal--favourite')) {
                $('.favourites-informer').show();
            }
        }, 500);
    };

    _bindFlat = () => {
        let self = this;

        self._elems.$_.addClass('ifacade-section-modal--flat');
        let $container = self._elems.$_.children('.ifacade-container');
        if (!$container.length) return;

        let done = function(){
            $(Utils.renderTemplate('flat-modal', {
                flat     : self._args.flat,
                floors   : self._args.floors,
                floor    : self._args.floor,
                section  : self._args.section,
                building : self._args.building,
                widget   : self._args.widget
            })).appendTo($container);

            self._elems.$_.find('a[data-open]').click(function(e){
                e.preventDefault();

                let $open = $($(this).data('open'));
                $open.addClass($open.data('opened'));

                $open.children('a').click(function(e){
                    e.preventDefault();
                    $open.removeClass($open.data('opened'));
                })
            });

            self._elems.$_.find('[data-action="request"]').unbind().on('click', function(e){
                e.preventDefault();
                let target = $(this).data('target');
                self._elems.$form.find('[name="target"]').val(target);
                self._elems.$form.find('[name="id"]').val($(this).data('id'));
                if (target == 'flat') {
                    self._elems.$form.find('.ifacade-form__inner .ifacade-form__title').text('Оставьте заявку, чтобы узнать больше о помещении');
                }
                else if (target == 'plan') {
                    self._elems.$form.find('.ifacade-form__inner .ifacade-form__title').text('Оставьте заявку, чтобы узнать больше о предложениях');
                }

                if (Object.keys(self._args.widget).length) {
                    self._elems.$form.find('[name="widget_id"]').val(self._args.widget.widget_id);
                }

                self._elems.$form.trigger('open');
            });

            $container.find('[data-fancybox]').fancybox({
                hash   : false,
                thumbs : {
                    autoStart : true
                }
            });

            setTimeout(function(){
                self._elems.$_.trigger('open');

                self._elems.$_.find('a[data-open]').each(function(){
                    let $open = $($(this).data('open'));
                    $open.children().each(function(){
                        if (!$(this).children().length) {
                            $(this).remove();
                        }
                    })
                });
            }, 200);
        };

        if (self._args.flat.assign) {
            done();
        } else {
            let params = '';
            if (Object.keys(self._args.widget).length) {
                params += '&widgetId=' + self._args.widget.widget_id
            }
            else {
                params += '&siteId=' + ifacade.site.site_id
            }

            iLoader.increase();
            Request.send({
                path: (Object.keys(self._args.widget).length ? ifacade.constants.www : '') + '/api/objects/' + self._args.flat.object_id + '/buildings/' + self._args.flat.building_id + '/sections/' + self._args.flat.section_id + '/flats/' + self._args.flat.id + '?' + params,
                dataType: 'json',

                done: function (response) {
                    iLoader.decrease();
                    if (response.flat) {
                        self._args.flat = Object.assign(self._args.flat, response.flat);
                        /*if (args.module) {
                            args.module.updateFlat(args.module, response.flat);
                        }*/
                        done();
                    } else {
                        $container.addClass($container.attr('class') + '__404');
                        $(Utils.renderTemplate('404-modal')).appendTo($container);

                        setTimeout(function () {
                            self._elems.$_.trigger('open');
                        }, 200);
                    }
                }
            });
        }
    };

    _bindPlan = () => {
        let self = this;

        self._elems.$_.addClass('ifacade-section-modal--plan');
        let $container = self._elems.$_.children('.ifacade-container');
        if (!$container.length) return;

        let done = function() {
            $(Utils.renderTemplate('plan-modal', {
                plan       : self._args.plan,
                flatsCount : self._args.flats.count,
                widget     : self._args.widget
            })).appendTo($container);

            self._elems.$_.find('a[data-open]').click(function(event){
                event.preventDefault();

                let $open = $($(this).data('open'));
                $open.addClass($open.data('opened'));

                $open.children('a').click(function(e){
                    e.preventDefault();
                    $open.removeClass($open.data('opened'));
                })
            });

            self._elems.$_.find('[data-action="request"]').unbind().on('click', function(e){
                e.preventDefault();
                const target = $(this).data('target');
                self._elems.$form.find('[name="target"]').val(target);
                self._elems.$form.find('[name="id"]').val($(this).data('id'));
                if (target == 'flat') {
                    self._elems.$form.find('.ifacade-form__inner .ifacade-form__title').text('Оставьте заявку, чтобы узнать больше о помещении');
                }
                else if (target == 'plan') {
                    self._elems.$form.find('.ifacade-form__inner .ifacade-form__title').text('Оставьте заявку, чтобы узнать больше о предложениях');
                }
                self._elems.$form.trigger('open');
            });

            self._elems.$_.find('[data-action="flats"]').unbind().on('click', function(e){
                e.preventDefault();
                self._elems.$_.trigger('close');

                self._elems.$_.trigger('close');
                if (self._elems.$trigger.hasClass('view-tile-item__link')) {
                    self._elems.$trigger.parent().children('.view-tile-item__button').trigger('click');
                }
                else if (self._elems.$trigger.parents('.content-view__list').length) {
                    self._elems.$trigger.parents('.section-main__content').find('.view-tile-item__button[data-id="' + self._elems.$trigger.data('id') + '"]').trigger('click');
                }
                else if (self._elems.$trigger.parents('.content-view__table').length) {
                    self._elems.$trigger.parents('.section-main__content').find('.view-tile-item__button[data-id="' + self._elems.$trigger.data('id') + '"]').trigger('click');
                }
            });

            $container.find('[data-fancybox]').fancybox({
                hash   : false,
                thumbs : {
                    autoStart : true
                }
            });

            setTimeout(function(){
                self._elems.$_.trigger('open');

                self._elems.$_.find('a[data-open]').each(function(){
                    let $open = $($(this).data('open'));
                    $open.children().each(function(){
                        if ($(this).children().length == 0) {
                            $(this).remove();
                        }
                    })
                });
            }, 200);
        };

        if (self._args.plan.assign) {
            done();
        } else {
            //$(Utils.renderTemplate('preload-modal')).appendTo($container);
            //var loader = new ifacadeLoader({ root : $container.find(iModal._classes.loader) });

            iLoader.increase();
            Request.send({
                path     : (Object.keys(self._args.widget).length ? ifacade.constants.www : '') + '/api/objects/plans/' + self._args.plan.id,
                dataType : 'json',
                done     : function (response) {
                    iLoader.decrease();
                    if (response.plan) {
                        self._args.plan = Object.assign(self._args.plan, response.plan);
                        /*if (args.module) {
                            args.module.updatePlan(args.module, response.plan);
                        }*/
                        done();
                    } else {
                        $container.addClass($container.attr('class') + '__404');
                        $(Utils.renderTemplate('404-modal')).appendTo($container);

                        setTimeout(function () {
                            self._elems.$_.trigger('open');
                        }, 200);
                    }

                    /*setTimeout(function(){
                        loader.$root.animate({'opacity': 0}, 400, function(){
                            loader.$root.remove();
                        });
                    }, 1000);*/
                }
            });
        }
    };

    _init = () => {
        let self = this;

        self._render();
        self._bindElements();
        self._bindUI();
        self._open();
    }
}

export default Modal;